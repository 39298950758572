.bp-cards-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.bp-card {
    display: flex;
    flex-direction: column;
    color: black;
    text-decoration: none;
    width: 300px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 2px 2px 15px 1px rgba(0, 0, 0, 0.2);
}

.bp-card img {
    height: 100px;
    width: 100%;
}

.bp-card-content {
    padding: 5px 10px;
    background-color: rgb(240, 240, 240);
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bp-card-content h1 {
    font-size: 20px;
    line-height: 23px;
    font-weight: 500;
}

.bp-card-content h2 {
    font-size: 15px;
    line-height: 15px;
    opacity: 0.5;
    font-weight: 600;
}

.bp-card-tags {
    padding: 5px;
    display: flex;
    justify-content: flex-end;
    background-color: #282c34;
    gap: 5px;
}

.bp-mini-tag {
    font-size: 10px;
    padding: 3px 8px;
    border-radius: 100px;
}