@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --dark-primary-bg: #1D1E2C;
  --dark-primary-text: white;



  --primary-bg: white;
  --primary-text: black;
}

body::-webkit-scrollbar {
  width: 12px;
}
body::-webkit-scrollbar-track {
  background: rgb(237, 237, 237);
}
body::-webkit-scrollbar-thumb {
  background-color: rgb(194, 194, 194);
  border-radius: 20px;
  border: 3px solid rgb(237, 237, 237);
}

code::-webkit-scrollbar {
  height: 12px;
}
code::-webkit-scrollbar-track {
  background: rgb(237, 237, 237);
}
code::-webkit-scrollbar-thumb {
  background-color: rgb(194, 194, 194);
  border-radius: 20px;
  border: 3px solid rgb(237, 237, 237);
}



body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--primary-bg);
  color: var(---primary-text);
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 800px;
  max-width: 100%;
  padding: 10px 25px;
}

img {
  object-fit: cover;
}
